<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="전체 수당">
                    - 팀 별 전체 수당을 확인 할 수 있습니다. 
                    <a class="btn-default float-right mt-10" href="/documents/AllStatisticsList">전체 통계</a>
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row table-serach">
                                <tbody>
                                    <tr>
                                        <th width="180">기간</th>
                                        <td>
                                            <CarrotDatePicker class="mr-10" v-model="comp.search.sdate"></CarrotDatePicker> ~
                                            <CarrotDatePicker class="ml-10" v-model="comp.search.edate"></CarrotDatePicker>
                                        </td>
                                        <th width="180">HQ매니저</th>
                                        <td>
                                            <CarrotStaff :idx_sector = "comp.search.idx_sector" :idx_team = "comp.search.idx_team" v-model="comp.search.idx_staff"></CarrotStaff>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="180">부문</th>
                                        <td>
                                            <CarrotSector v-model="comp.search.idx_sector"></CarrotSector>
                                        </td>
                                        <th width="180">팀</th>
                                        <td>
                                            <CarrotTeam v-model="comp.search.idx_team" :idx_sector="comp.search.idx_sector"></CarrotTeam>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-right mt-10" @click="comp.find()">검색</button>
                            <div class="clear"></div>
                            
                            <table class="table-col mt-50" v-if="comp.total">
                                <colgroup>
                                    <col span="7">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="5">외근수당</th>
                                        <th rowspan="2">야근수당</th>
                                        <th rowspan="2">추가수당</th>
                                    </tr>
                                    <tr>
                                        <th>오픈</th>
                                        <th>참관</th>
                                        <th>타지</th>
                                        <th>합숙</th>
                                        <th>합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.numberToText(comp.total.s01.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s02.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s03.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s04.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.total.price)}}</td>
                                        <td>{{comp.numberToText(comp.total_overtime)}}</td>
                                        <td>{{comp.numberToText(comp.total_extrapay)}}</td>
                                    </tr>
                                    <tr class="total">
                                        <td colspan="7" class="total"><span class="color-1">총 합계 {{comp.numberToText(comp.total_sum)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="dp-table w-100per mt-50 mb-20">
                                <div class="tabDefult-col">
                                <button class="tab-default" :class="{'on':comp.tab=='outside'}" @click="comp.tab='outside'">외근수당</button>
                                <button class="tab-default" :class="{'on':comp.tab=='overtime'}" @click="comp.tab='overtime'">야근수당</button>
                                <button class="tab-default" :class="{'on':comp.tab=='extra'}" @click="comp.tab='extra'">추가수당</button>
                                    <button class="btn-default float-right" @click="comp.getExcel()">엑셀다운로드</button>
                                </div>
                            </div>

                            <div v-if="comp.tab=='outside'">
                                <table class="table-col">
                                    <colgroup>
                                        <col width="60">
                                        <col width="110">
                                        <col width="160">
                                        <col width="100">
                                        <col width="230">
                                        <col width="140">
                                        <col width="140">
                                        <col width="130">
                                        <col width="130">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>방문일시</th>
                                            <th>고객사</th>
                                            <th>외근목적</th>
                                            <th>클래스</th>
                                            <th>금액</th>
                                            <th>HQ매니저</th>
                                            <th>부문</th>
                                            <th>팀</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(itm, idx) in comp.expense" :key="idx">
                                            <td>{{comp.expense.length-idx}}</td>
                                            <td><span class="btn-view">{{itm.vdate}}</span></td>
                                            <td>{{itm.company}}</td>
                                            <td>{{itm.out_prc_type}}</td>
                                            <td>{{itm.out_prc_class}}</td>
                                            <td>{{comp.numberToText(itm.out_prc_price)}}</td>
                                            <td>{{itm.ename}} ({{itm.kname}})</td>
                                            <td>{{itm.sector}}</td>
                                            <td>{{itm.team}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="comp.expense.length==0">
                                        <tr>
                                            <td colspan="9">등록된 자료가 없습니다.</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>



                            <table class="table-col" v-if="comp.tab=='overtime'">
                                <colgroup>
                                    <col width="80">
                                    <col width="140">
                                    <col width="*">
                                    <col width="100">
                                    <col width="130">
                                    <col width="110">
                                    <col width="170">
                                    <col width="130">
                                    <col width="130">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>야근일시</th>
                                        <th>근무내용</th>
                                        <th>퇴근시간</th>
                                        <th>금액</th>
                                        <th>결재 완료일</th>
                                        <th>HQ매니저</th>
                                        <th>부문</th>
                                        <th>팀</th>
                                    </tr>
                                </thead>
                                <tbody v-if="comp.overtime.length>0">
                                    <tr v-for="(itm,idx) in comp.overtime" :key="idx">
                                        <td>{{comp.overtime.length-idx}}</td>
                                        <td><span class="btn-view">{{itm.cdate}}</span></td>
                                        <td>{{itm.memo}}</td>
                                        <td>{{itm.etime}}</td>
                                        <td>{{comp.numberToText(itm.cost)}}</td>
                                        <td>{{itm.createdate}}</td>
                                        <td>{{itm.ename}} ({{itm.kname}})</td>
                                        <td>{{itm.sector}}</td>
                                        <td>{{itm.team}}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="comp.overtime.length==0">
                                    <tr>
                                        <td colspan="9">등록된 자료가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table-col" v-if="comp.tab=='extra'">
                                <colgroup>
                                    <col width="80">
                                    <col width="140">
                                    <col width="*">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="170">
                                    <col width="130">
                                    <col width="130">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>근무일시</th>
                                        <th>근무내용</th>
                                        <th>근무시간</th>
                                        <th>금액</th>
                                        <th>결재 완료일</th>
                                        <th>HQ매니저</th>
                                        <th>부문</th>
                                        <th>팀</th>
                                    </tr>
                                </thead>
                                <tbody v-if="comp.extrapay.length>0">
                                    <tr v-for="(itm,idx) in comp.extrapay" :key="idx">
                                        <td>{{comp.extrapay.length-idx}}</td>
                                        <td><span class="btn-view">{{itm.cdate}}</span></td>
                                        <td>{{itm.memo}}</td>
                                        <td>{{itm.stime}} ~ {{itm.etime}}</td>
                                        <td>{{comp.numberToText(itm.cost)}}</td>
                                        <td>{{itm.createdate}}</td>
                                        <td>{{itm.ename}} ({{itm.kname}})</td>
                                        <td>{{itm.sector}}</td>
                                        <td>{{itm.team}}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="comp.extrapay.length==0">
                                    <tr>
                                        <td colspan="9">등록된 자료가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>


                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>


<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import CarrotSector from '@/components/common/CarrotSector.vue';
import CarrotTeam from '@/components/common/CarrotTeam.vue';
import CarrotStaff from '@/components/common/CarrotStaff.vue';
// import { useStore } from 'vuex'


export default {
    components: {
        CarrotDatePicker,
        CarrotSector,
        CarrotTeam,
        CarrotStaff,
    },
    setup() {
        const toast = useToast();
        const router = useRouter();
        // const store = useStore();

        const comp = reactive({
            search : {
                sdate : "2021-09-01",
                edate : "2021-09-30",
                idx_office : 0,
                idx_team : 0,
                idx_staff : 0,
            },
            list : [],
            overtime : [],
            total_overtime : 0,
            extrapay : [],
            total_extrapay : 0,
            expense : [],
            years : [],
            months : [],
            txt_filter : "",
            tab: "outside",
            total : null,
            indiv : null,
            corp : null,

            toStringByFormatting : (source, delimiter = '-') => {
                 const year = source.getFullYear();
                 const month = comp.leftPad(source.getMonth() + 1);
                 const day = comp.leftPad(source.getDate());
                 return [year, month, day].join(delimiter); 
            },
            leftPad : (value) => {
                if (value >= 10) { 
                    return value; 
                } 
                return `0${value}`;
            },

            numberToText : (num) => {
                return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            getExcel : () => {
                let params = comp.search;
                params.ctype = comp.tab;
                let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
                location.href="/rest/extrapay/allExtraPayExcel?" + str;
            },


            find : () => {
                let param = {params : comp.search};
                axios.get('/rest/extrapay/allExtraPay', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.total = res.data.total;
                        comp.expense = res.data.list;
                        comp.overtime = res.data.overtime;
                        comp.extrapay = res.data.extrapay;
                        comp.total_extrapay = res.data.total_extrapay;
                        comp.total_overtime = res.data.total_overtime;
                        comp.total_sum = res.data.total_sum;
                        // for(let i=0;i<comp.overtime.length;i++) {
                        //     comp.total_overtime = parseInt(comp.overtime[i].cost);
                        // }
                        // for(let i=0;i<comp.extrapay.length;i++) {
                        //     comp.total_extrapay = parseInt(comp.extrapay[i].cost);
                        // }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            modify : (idx) => {
                router.push({ 
                    path:"/documents/ExpenseInputModify/" + idx
                });
            },
        });

        onMounted(() => {
            // if(store.state.position!='리드') {
            //     toast.error("리드 이상 접근 가능한 페이지 입니다.");
                /*
                router.push({
                    path:"/documents/MyExtraPayManagement"
                });

                 */
                // return;
            // }

            let today = new Date();
            comp.search.sdate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth(), 1));
            comp.search.edate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth()+1, 0));

            comp.find();
            
        });

        return {comp};
    }

}
</script>


<style lang="scss" scoped>
</style>